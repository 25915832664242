import React from 'react'
import {Row,Col} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import FaqImage from '../images/FaqImage.png'
import Fade from 'react-reveal/Fade';

const FaqHome = () => {
  return (
    <div>
      <div className='container'>
        {/* <h6 style={{ color: '#FC4243' }}>FAQ</h6> */}
        <h1 style={{fontFamily: 'sans-serif'}}>Frequently Asked Questions</h1>
        <div>
          <Row>
            <Col className='mt-4'>
            <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 style={{fontFamily: 'lato,sans-serif'}}>How much does it cost to ship a package in Canada?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Package shipping costs in Canada vary based on factors like package weight, dimensions, origin and destination locations. We offer competitive Canada shipping rates with no hidden fees, making it easy to ship packages affordably across the country.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What box and packaging supplies do you provide?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                     Our selection of high-quality packing supplies includes sturdy boxes in various sizes, heavy-duty packing tape, bubble wrap, packing peanuts, and other protective materials to ensure your items are safely packaged for shipping.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you ship and deliver on weekends and holidays?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                      Yes, we provide weekend and holiday delivery services to ensure your packages reach their destinations without delays, no matter the day or time.weekend delivery, holiday delivery
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What payment methods do you accept? </h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                     We accept multiple payment options for your convenience, including online payment via credit card, debit card, PayPal, or direct bank transfer to streamline the shipping process.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I get a quote and schedule a package pickup?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    To get a quote for package shipping costs and schedule a package pickup, you can use our convenient pricing tool or contact our customer service team directly. We will provide a competitive quote based on your package details                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What is your process for safely transporting my packages?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                     Our process for safe transportation involves secure handling of your packages, with trained staff following best practices for loading and unloading. We use state-of-the-art trucks equipped with GPS tracking and climate control to ensure your packages are transported safely.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I track my package delivery status?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    You can easily track the delivery status of your package using our online package tracking system. Simply enter your tracking number to view real-time updates on your shipment's location and estimated delivery time.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </Col>
            <Col className='FaqImage'>
              <img src={FaqImage} alt='Image' style={{width:'100%', marginBottom:'20px'}} /> 
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default FaqHome
