  import React,{useState,useEffect} from 'react'
import { Button,Row,Col, Nav } from 'react-bootstrap'
import Header from '../components/Header';
import acvlbanner from '../images/acvl-banner.png'
import { Link,useNavigate } from "react-router-dom";
import deliveryMale from '../images/Abouthome.png'
import Check from '../images/Bubble.gif'
import support from '../images/support.gif'
import past from '../images/past.gif'
import Shield from '../images/Shield.gif'
import Kindness from '../images/Kindness.gif'
import TrackImage from '../images/TrackImage.png'
import Fade from 'react-reveal/Fade';
import Select from 'react-select'
import Build from '../images/build.png'
import { IoRemoveOutline } from "react-icons/io5";
import { FaChartLine } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import {useSpring, animated} from 'react-spring';
import ServicePage from '../components/ServicePage';
import CounterMainHome from '../components/CounterMainHome';
import ClientScroll from '../components/ClientScroll';
import FaqHome from '../components/FaqHome';
import Testmonial from '../components/Testmonial';
import { ToastContainer, toast } from 'react-toastify';
import FooterMainBg from '../images/footer-bg.png'
import { FaFacebook } from "react-icons/fa6";
import { TfiInstagram } from "react-icons/tfi";
import { RiTwitterXLine } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import  ACVllogofooter from '../images/ACVl-logo-footer.png'
import { BsFillChatSquareQuoteFill } from "react-icons/bs";
import { MdCall } from "react-icons/md";
import {Helmet} from 'react-helmet'


function Number ({n}) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 3000,
    config: { mass: 1, tension: 20, friction: 10 },
})
return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}


const Home = () => {

  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [predictionsto, setPredictionsto] = useState([]);
  const [inputValueto, setInputValueto] = useState("");
  const [predictionsInput, setpredictionsInput] = useState("");
  const [predictionsInputto, setpredictionsInputto] = useState("");



  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);




  const navigate = useNavigate();

  ///////////// FROM City API ///////////////
  const fetchDictionFrom = async (inputText) => {
    // if (inputText.length > 0) {
      try {
        const apiUrl = `https://allcanadavanlines.com/acvlbackend/api/google-places-autocomplete?inputText=${inputText}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        if (data.predictions) {
          const options = data.predictions.map((prediction) => ({
            value: prediction.description,
            label: prediction.description,
          }));
          setPredictions(options);
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    // }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setpredictionsInput(selectedOption);
    } else {
      setpredictionsInput(null); // or setpredictionsInput(defaultValue);
    }
  };

  useEffect(() => {
    fetchDictionFrom(inputValue);
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  ///////////// TO City API ///////////////
  const fetchDictionTo = async (inputText) => {
    // if (inputText.length > 1) {
      try {
        const apiUrl = `https://allcanadavanlines.com/acvlbackend/api/google-places-autocomplete?inputText=${inputText}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        if (data.predictions) {
          console.log("predictions", data.predictions);
          const optionsto = data.predictions.map((prediction) => ({
            value: prediction.description,
            label: prediction.description,
          }));
          setPredictionsto(optionsto);
        }
      } catch (error) {
        console.error("Error fetching predictions:" , error);
      }
    // }
  };

  const handleSelectChangeto = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setpredictionsInputto(selectedOption);
    } else {
      setpredictionsInputto(null); 
    }
  };

  useEffect(() => {
    fetchDictionTo(inputValueto);
  }, [inputValueto]);

  const handleInputChangeto = (newValue) => {
    setInputValueto(newValue);
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleNextButtonClick = (event) => {
    event.preventDefault();     
    if (predictionsInput && predictionsInput.label && predictionsInputto && predictionsInputto.label) {
      const dataToSend = {
        from: predictionsInput.label,
        to: predictionsInputto.label,
      };

      console.log('NEXT button clicked. Navigating with data:', dataToSend);

      // Use navigate to navigate and pass data through state
      navigate('/CalenderForm', { state: { data: dataToSend } });

      

    } else {  
      toast.warn('locations are required', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
  };

  
  useEffect(() => {
    // Function to get the IP address
    const fetchIpAddress = async () => {
      try {
        // Fetch the IP address from IPify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;

        // Send the IP address to your API
        await sendIpAddressToApi(ipAddress);

      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Function to send the IP address to your API
    const sendIpAddressToApi = async (ipAddress) => {
      try {
        const response = await fetch('https://allcanadavanlines.com/acvlbackend/api/ipaddress', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipaddress: ipAddress }),
        });

        if (response.ok) {
          console.log('IP address sent successfully');
        } else {
          console.error('Failed to send IP address');
        }
      } catch (error) {
        console.error('Error sending IP address:', error);
      }
    };

    // Call the function to fetch IP and send it to your API
    fetchIpAddress();

  }, []); // Empty dependency array ensures this runs only once when the component mounts








  
  return (
    <div id="quote">
       <Helmet>
        <meta name="description" content="Are you looking for a reliable moving company in Canada? Discover top-rated movers offering local, long-distance, and international Moving services."/>
      </Helmet>
    <Header />
      <ToastContainer />

{/* -------------------------- START BANNER --------------------------- */}
      <div className='Banner' style={{backgroundImage: `url(${acvlbanner})`,backgroundSize:'cover',backgroundRepeat: 'no-repeat',height:'295px',backgroundPosition: '105% 0%' }}>
        <div className='container-fluid'>
          <Row>
            <Col xs={7} className='Banner-title' style={{margin:'50px 0px 0px 0px'}}>
          <div  className='bannerHeading' style={{marginLeft: '50px'}}>
              <Fade left>
              <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>
               <main class="d-flex">
                <p className='prr mb-0'>Make Moving</p>
                <section class="animation">
                  <div class="first" style={{color:'#FEFFFA'}}><div>Easy</div></div>
                  <div class="second" style={{color:'#FEFFFA'}}><div>Safe And Sound</div></div>
                  <div class="third" style={{color:'#FEFFFA'}}><div>Affordable</div></div>
                </section>
              </main>
               </h1>
              {/* <h1 style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>Seamless moves across Canada!</h1> */}
              <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
              </Fade>
              {/* <div className='mt-4'>
                <Link to='/Aboutus' ><Button className='button-21'>Read More<RiArrowRightSLine size={19} className='mb-0' /></Button></Link>
              </div> */}
            </div>
            <Fade bottom>
            <div id='Process' className="display-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'25px',backgroundColor:'#393939', width:'110%',marginLeft:'50px'}}>
              <div className='container my-4'>
                <Row>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving from *</h6>
                    <Select 
                        isClearable
                        value={predictionsInput}
                        options={predictions}
                        onInputChange={handleInputChange}
                        onChange={handleSelectChange}
                        noOptionsMessage={() => 'Search Your City'}
                        isRequired placeholder='City' />
                  </Col>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving to *</h6>
                    <Select 
                     isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='City' />
                  </Col>
                  <Col xs={2} style={{marginTop:'20px'}}>
                     {/* <a href='/CalenderForm' ><Button style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button></a> */}
                     <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528',fontWeight: '600'}}>GET QUOTE</Button>
                  </Col>
                </Row>
              </div>
            </div></Fade>
            </Col>
            <Col xs={5}>
            <Fade right>
              <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{ width:'330px',position: 'absolute',right: '0',marginTop: '3px'}} /></Fade>
            </Col>
          </Row>
        </div>
      </div>
{/* -------------------------- START BannerReponse --------------------------- */}
       <div id='Process' className='Banner BannerReponse' style={{backgroundColor:'#FC4243',backgroundSize:'cover',backgroundRepeat: 'no-repeat',height:'348px',backgroundPosition: '105% 0%' }}>
        <div className='container-fluid'>
          <Row>
            <Col xs={12} className='Banner-title' style={{margin:'100px 0px 0px 0px'}}>
          <div className='bannerHeading' style={{marginLeft: '50px'}}>
              <Fade left>
              <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: "bold"}}>
              <main class="d-flex">
                <p className='prr'>Make Moving</p>
                <section class="animation">
                  <div class="first" style={{color:'#FEFBF0'}}><div>Easy</div></div>
                  <div class="second" style={{color:'#FEFBF0'}}><div>Safe And Sound</div></div>
                  <div class="third" style={{color:'#FEFBF0'}}><div>Affordable</div></div>
                </section>
              </main>
               </h1>
              <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
              </Fade>
              <div className='mt-4'>
                <a href="tel:+1-833-372-9071" style={{textDecoration:'none'}} ><Button className='button-21'><MdCall size={22} className='me-2 heart text-light'/>Contact Our Team</Button></a>
              </div>
            </div>
            <Fade bottom>
            <div className="display-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'50px',backgroundColor:'#393939', width:'100%',marginLeft:'50px'}}>
              <div className='container my-4'>
                <Row>
                  <Col xs={5}>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                    <Select 
                        isClearable
                        value={predictionsInput}
                        options={predictions}
                        onInputChange={handleInputChange}
                        onChange={handleSelectChange}
                        noOptionsMessage={() => 'Search Your City'}
                        isRequired placeholder='City' />
                  </Col>
                  <Col xs={5}>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                    <Select 
                     isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='City' />
                  </Col>
                  <Col xs={2} style={{marginTop:'20px'}}>
                     <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button>
                  </Col>
                </Row>
              </div>
            </div></Fade>
            </Col>
            <Col xs={5}>
            <Fade right>
              <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{position: 'absolute',right: '0'}} /></Fade>
            </Col>
          </Row>
        </div>
      </div> 
{/* ------ Mobile View ----------*/}
      <Fade bottom>
        <div className='container mb-3'>
            <div className="Mobile-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'30px',backgroundColor:'#393939', width:'100%'}}>
              <div className='container my-4'>
                <Row>
                  <Col md={5}>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                    <Select 
                       isClearable
                       value={predictionsInput}
                       options={predictions}
                       onInputChange={handleInputChange}
                       onChange={handleSelectChange}
                       noOptionsMessage={() => 'Search Your City'}
                       isRequired placeholder='From City' />
                  </Col>
                  <Col md={5} className='mt-3'>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                    <Select   
                    isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='To City' />
                  </Col>
                  <Col md={2} className="d-flex justify-content-center" style={{ marginTop: '25px' }}>
                    <Button className='button-71' onClick={handleNextButtonClick} style={{ backgroundColor: '#202528', borderColor: '#202528',fontWeight: '600' }}>GET QUOTE</Button>
                  </Col>
                </Row>
              </div>
            </div>
            </div>
      </Fade>
{/* -------------------------- END BANNER --------------------------- */}

{/* -------------------------- START WORK-PROCESS BANNER --------------------------- */}
    <div  className='container' style={{marginTop:'30px', position:'relative',zIndex: '-1'}}>
      {/* <span style={{color:'#FC4243'}}>ACTION STEPS</span>
      <h2 style={{fontFamily: 'Lato,sans-serif'}}>Move it. Improve it. Groove it </h2> */}
      <span className='hrLine' style={{ background: 'black',width: '80%',position: 'absolute',top: '60px',border: '1px dotted red',height: '2px',left: '70px',zIndex: '-1'}}/>
     <Row className='mt-4 workProcess'>
        <Col style={{textAlign: '-webkit-center'}}>
          <div style={{backgroundColor:'#ffff', border: '3px solid #FC4243', borderRadius: '50%', width: '125px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Check} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
          </div>
          <div className='my-3 text-center'>
            <h5 style={{fontSize:'17px',fontFamily: 'Lato,sans-serif'}}>Get Quote</h5>
          </div>
        </Col>
      
        <Col style={{textAlign: '-webkit-center'}}>
          <div style={{backgroundColor:'#ffff', border: '3px solid #FC4243', borderRadius: '50%', width: '125px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={support} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
          </div>
          <div className='my-3 text-center'>
            <h5 style={{fontSize:'17px',fontFamily: 'Lato,sans-serif'}}>Engage a personal Relationship Manager</h5>
          </div>
        </Col>
    
        <Col style={{textAlign: '-webkit-center'}}>
          <div style={{backgroundColor:'#ffff', border: '3px solid #FC4243', borderRadius: '50%', width: '125px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={past} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
          </div>
          <div className='my-3 text-center'>
            <h5 style={{fontSize:'17px',fontFamily: 'Lato,sans-serif'}}>Get Periodic Update</h5>
          </div>
        </Col>
    
        <Col style={{textAlign: '-webkit-center'}}>
          <div style={{backgroundColor:'#ffff', border: '3px solid #FC4243', borderRadius: '50%', width: '125px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Shield} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
          </div>
          <div className='my-3 text-center'>
            <h5 style={{fontSize:'17px',fontFamily: 'Lato,sans-serif'}}>Professionally managed Load and Unload</h5>
          </div>
        </Col>
   
        <Col id='Aboutus'  style={{textAlign: '-webkit-center'}}>
          <div style={{backgroundColor:'#ffff', border: '3px solid #FC4243', borderRadius: '50%', width: '125px', height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Kindness} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
          </div>
          <div className='my-3 text-center'>
            <h5 style={{fontSize:'17px',fontFamily: 'Lato,sans-serif'}}>Honest and Suprise free Billing</h5>
          </div>
        </Col>
      </Row> 

      
{/* -------------- Mobile View ------------------- */}
    <Row className='mt-4 workProcessMobile'>
      <Col className='text-center'>
      <div style={{ border: '3px solid #FC4243', borderRadius: '50%', width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',margin:'auto' }}>
      <img src={Check} alt='form gif' style={{ width: '70%', height: 'auto', borderRadius: '50%' }} />
    </div>
        <div className='my-3'>
          <h3 style={{fontFamily: 'Lato,sans-serif'}}>Get Quote</h3>
          <p style={{fontFamily: 'Lato,sans-serif'}}>Request hassle-free moving quote for personalized service</p>
        </div>
      </Col>
      <Col className='text-center'>
        <div style={{ border: '3px solid #FC4243', borderRadius: '50%', width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',margin:'auto'  }}>
          <img src={support} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
        </div>
        <div className='my-3'>
          <h3 style={{fontFamily: 'Lato,sans-serif'}}>Engage a personal Relationship Manager</h3>
          <p style={{fontFamily: 'Lato,sans-serif'}}>Assigned expert oversees every aspect of relocation</p>
        </div>
      </Col>
      <Col className='text-center'>
        <div style={{ border: '3px solid #FC4243', borderRadius: '50%', width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',margin:'auto' }}>
          <img src={past} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
        </div>
        <div className='my-3'>
          <h3 style={{fontFamily: 'Lato,sans-serif'}}>Get Periodic Update</h3>
          <p style={{fontFamily: 'Lato,sans-serif'}}> Regular communication keeps you informed throughout.</p>
        </div>
      </Col> 
      <Col className='text-center'>
        <div style={{ border: '3px solid #FC4243', borderRadius: '50%', width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',margin:'auto' }}>
          <img src={Shield} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
        </div>
        <div className='my-3'>
          <h3 style={{fontFamily: 'Lato,sans-serif'}}>Professionally managed Load and Unload</h3>
          <p style={{fontFamily: 'Lato,sans-serif'}}>Smooth coordination handles all move-related tasks</p>
        </div>
      </Col>
      <Col className='text-center'>
        <div style={{ border: '3px solid #FC4243', borderRadius: '50%', width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center',margin:'auto' }}>
          <img src={Kindness} alt='form gif' style={{ width: '70%', borderRadius: '50%' }} />
        </div>
        <div className='my-3'>
          <h3 style={{fontFamily: 'Lato,sans-serif'}}>Honest and Suprise free Billing</h3>
          <p style={{fontFamily: 'Lato,sans-serif'}}>Transparent, fixed pricing for cross-country moving services</p>
        </div>
      </Col>
    </Row>

    </div>
{/* -------------------------- END WORK-PROCESS BANNER --------------------------- */}

{/* -------------------------- START ABOUT BANNER --------------------------- */}

    <div  className='container' style={{marginTop:'80px',marginBottom:'30px'}}>
      <Row>
        <Col className='AboutImageColoum'>
        <Fade left>
          <img src={deliveryMale} alt='About' style={{width:'100%', borderRadius:'15px'}} className='AboutImage'  /></Fade>
        </Col>
        
        <Col className='ms-4'>
          <Fade right>
          <span style={{color:'#FC4243',fontFamily:'Lato, sans-serif'}}>ABOUT <IoRemoveOutline size={30} style={{marginBottom:'5px'}} /></span>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>We are leader in moving companies in Canada</h2>
          <p  style={{ lineHeight: '1.8',fontFamily: 'Lato, sans-serif' }}>
          {/* ACVL offers customized relocations across Canada with personalized care and attention. Specialists securely pack, transport, and unpack using top techniques. 2000+ satisfied customers. Trust us for hassle-free moves tailored to your needs. We handle the details so that you can move forward confidently. */}
          <a href='https://g.page/r/CXvf5e_TOMY2EBM' style={{color:'#000', textDecoration:'none'}} target="_blank">All Canada Van Lines</a> (ACVL), among top moving companies in Alberta, provides tailored relocations across Canada with personalized attention. Our specialists securely pack, transport, and unpack using top techniques. With 2000+ satisfied customers, trust us for hassle-free moves tailored to your needs. We handle the details so you can move forward confidently
          </p>  
           <Row>
            <Col>
            <div style={{display: 'flex'}}>
            <div style={{ border: '1px solid #FC4243', backgroundColor: '#FC4243', height: '50px', width: '50px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaChartLine size={30} style={{ color: '#ffff' }} />
            </div>
            <h1 className='AboutCount' style={{color:'#FC4243', marginLeft:'20px', fontWeight:'bolder',display:'flex'}}><Number n={3000}/> +</h1>
            </div>
            <h3 style={{fontFamily: 'Lato, sans-serif',marginLeft: '5px',marginTop: '10px',fontWeight: '600'}}>Project Done</h3>
            </Col>
            <Col>
            <div style={{display: 'flex'}}>
            <div style={{ border: '1px solid #FC4243', backgroundColor: '#FC4243', height: '50px', width: '50px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaTruckFast size={30} style={{ color: '#ffff', }} />
            </div>
            <h1 className='AboutCount' style={{color:'#FC4243', marginLeft:'20px', fontWeight:'bolder',display:'flex'}}><Number n={2000}/> +</h1>
            </div>
            <h3 style={{fontFamily: 'Lato, sansSerif',marginLeft: '5px',marginTop: '10px',fontWeight: '600'}}>Satisfied Client</h3>
            </Col>
          </Row>
          </Fade>
        </Col>
      </Row>
    </div>
{/* -------------------------- END ABOUT BANNER --------------------------- */}
{/* -------------------------- START SERVICE--------------------------- */}
      <div className='my-5'>
       <ServicePage/>
      </div>
{/* -------------------------- END SERVICE --------------------------- */}
{/* -------------------------- START SERVICE--------------------------- */}
      <div className='mt-5'>
         <CounterMainHome/>
      </div>
{/* -------------------------- END SERVICE --------------------------- */}
{/* -------------------------- START SERVICE--------------------------- */}
      <div className='mb-5'>
         <Testmonial/>
      </div>

{/* -------------------------- END SERVICE --------------------------- */}
{/* -------------------------- START SERVICE--------------------------- */}
      <div className='mb-5'>
         <ClientScroll/>
      </div>
{/* -------------------------- END SERVICE --------------------------- */}
{/* -------------------------- START SERVICE--------------------------- */}
      <div id='faq' className='mb-5'>
         <FaqHome/>
      </div>
{/* -------------------------- END SERVICE --------------------------- */}
<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="#quote" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>

{/* -------------------------- START SERVICE--------------------------- */}

<div>
        
        <div className='FooterTopSet' style={{position:'relative'}}>
          <h2 style={{position: 'absolute',right: '10%',top: '15%'}}>
          <main class="d-flex">
                <section class="animation">
                  <div class="first" style={{color:'#000'}}><div>"We go the extra mile with discounts"</div></div>
                  <div class="second" style={{color:'#000'}}><div>"With ACVL Movers, you can trust"</div></div>
                  <div class="third" style={{color:'#000'}}><div>"At ACVL, customers are family"</div></div>
                </section>
              </main>
          </h2>
          <img src={FooterMainBg} style={{width:'100%'}} alt='footer' />
          <div style={{backgroundColor:'#FC4243',padding: '30px 0',height: '140px',}}>
          <div className='container-fluid' style={{marginLeft:'20px',top: '75%',zIndex: '999', color:'#ffff',width:'auto'}}>
          <Row>
            <Col xs={8} style={{ alignSelf: 'center'}}>
              <h2 style={{color:'#ffff',fontFamily: 'Lato, sans-serif',fontWeight: '700'}}>Sit back and relax</h2>
              <h4 style={{color:'#ffff',fontFamily: 'Lato, sans-serif'}} className='mt-3'>ACVL has your move covered with care from start to end</h4>
            </Col>
            <Col xs={4} style={{position:'relative',textAlign:'center'}}>
              <div style={{position: 'absolute',top: '-115%',right: '10%'}}>
              <span style={{color:'#ffff', marginBottom:'0px'}} className='mb-0'>CALL US TOLL FREE</span>
              <a style={{textDecoration:'none'}} href="tel:+1-833-372-9071"><h2 style={{color:'#ffff'}} className='mt-1'>1-833-372-9071</h2></a>
              <div style={{textAlign: '-webkit-center'}} >
               <div style={{width:'50px', height:'50px', borderRadius:'50%',border:'1px solid #000',
               justifyContent: 'space-around',alignItems: 'center',display: 'flex', backgroundColor:'#23262B'}}>
                <h5 className='mt-1'>OR</h5>
                </div>
               </div>
               <a href='#quote' style={{textDecoration:'none'}}><Button style={{width:'222px', height:'55px', marginTop:'5px', backgroundColor:'#D9D8D8', color:'#000'}} className='FooterButtonGetq'>
                <h5 className='mb-0' style={{fontFamily:'sans-serif'}}>GET A QUOTE</h5></Button></a>

              </div>
            </Col>
          </Row>
        </div>
          </div>
        </div>
      <header>
</header>

<div style={{backgroundColor:'#23262B',overflow: 'hidden',position: 'relative'}} >
    <img src={Build} alt="imagehnxbd" style={{opacity: '0.2', position: 'absolute',left: '0',top: '0',width: '100%',height: 'auto'}}  />
      <div className='container'>
      <Row style={{ position: 'relative' }}>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/mover-in-edmonton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Mover in Edmonton</h6>
        </a>
        <a href='/movers-in-calgary' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Calgary</h6>
        </a>
        <a href='/movers-in-montreal' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Montreal</h6>
        </a>
        <a href='/movers-in-vancouver' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Vancouver</h6>
        </a>
        <a href='/movers-in-winnipeg' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Winnipeg</h6>
        </a>
        <a href='/movers-in-halifax' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Halifax</h6>
        </a>
        <a href='/movers-in-moncton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Moncton</h6>
        </a>
        <a href='/movers-in-ottawa' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Ottawa</h6>
        </a>
        <a href='/movers-in-toronto' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Toronto</h6>
        </a>
        <a href='/movers-in-victoria' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Victoria</h6>
        </a>
        <a href='/movers-in-newfoundland-and-labrador' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Newfoundland and Labrador</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-alberta' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Alberta</h6>
        </a>
        <a href='/movers-in-red-deer' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Red Deer</h6>
        </a>
        <a href='/movers-in-medicine-hat' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Medicine Hat</h6>
        </a>
        <a href='/movers-in-saskatchewan' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Saskatchewan</h6>
        </a>
        <a href='/movers-in-lloydminster' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Lloydminster</h6>
        </a>
        <a href='/movers-in-grande-prairie' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Grande Prairie</h6>
        </a>
        <a href='/movers-in-camrose' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Camrose</h6>
        </a>
        <a href='/movers-in-lethbridge' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Lethbridge</h6>
        </a>
        <a href='/movers-in-fort-mcmurray' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Fort McMurray</h6>
        </a>
        <a href='/movers-in-british-columbia' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in British Columbia</h6>
        </a>
        <a href='/movers-in-nunavut' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Nunavut</h6>
        </a>
      </Col>
      <Col xs={12} md={4} className='mt-4' style={{ padding: '0 70px' }}>
        <a href='/movers-in-manitoba' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Manitoba</h6>
        </a>
        <a href='/movers-in-hamilton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Hamilton</h6>
        </a>
        <a href='/movers-in-thunder-bay' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Thunder Bay</h6>
        </a>
        <a href='/movers-in-kingston' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Kingston</h6>
        </a>
        <a href='/movers-in-quebec-city' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Québec City</h6>
        </a>
        <a href='/movers-in-greater-sudbury' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Greater Sudbury</h6>
        </a>
        <a href='/movers-in-brampton' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Brampton</h6>
        </a>
        <a href='/movers-in-new-brunswick' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in New Brunswick</h6>
        </a>
        <a href='/movers-in-nova-scotia' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in Nova Scotia</h6>
        </a>
        <a href='/movers-in-north-york' style={{ textDecoration: 'none' }}>
          <h6 className='mb-3' style={{ color: '#ffff', fontFamily: 'Lato' }}>Movers in North York</h6>
        </a>
      </Col>
    </Row>
    
      </div>
    </div>
 <footer class="footer">
  <div class="footer__addr">
    <h1 class="footer__logo">
      <img src={ACVllogofooter} style={{width:'50%'}} alt='Logo' />
    </h1>
        
    <h2>
    <a href='https://www.facebook.com/profile.php?id=61556106046609' target="_blank" style={{color:'#ffff'}}><FaFacebook style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
      <a href='https://www.instagram.com/allcanadavanlines/' target="_blank" style={{color:'#ffff'}}><TfiInstagram style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
      <a href='https://twitter.com/Acvl453280' target="_blank" style={{color:'#ffff'}}><RiTwitterXLine style={{cursor:'pointer'}} className='me-3 FooterSolicalMedia' size={25}/></a>
    </h2>
    <div>
      <p style={{fontFamily:'Lato, sans-serif'}}>
        ACVL has set the standard for seamless household and commercial relocations across Canada. With over 2000 satisfied customers, you can trust us to engineer a hassle-free move tailored to your needs.     
      </p>
    </div>
  </div>
  
  <ul class="footer__nav">
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">Our Services</h1>

      <ul class="nav__ul">
      <li>
          <a href="/local-movers"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Local Movers</a>
        </li>

        <li>
          <a href="/long-distance-movers"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Long Distance Movers</a>
        </li>
            
        <li>
          <a href="/junk-removal"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Junk Removal</a>
        </li>
        <li>
          <a href="/packaging-services"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Packing Services</a>
        </li>
        <li>
          <a href="/moving-supplier"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Moving Supplies</a>
        </li>
        <li>
          <a href="/storage-services"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Storage Services</a>
        </li>
        {/* <li>
          <a href="#"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Weight Calculation</a>
        </li> */}
      </ul>
    </li>
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">About Company</h1>

      <ul class="nav__ul">
        <li>
          <a href="#Aboutus"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Our Story</a>
        </li>

        <li>
          <a href="#Process"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Process</a>
        </li>
        <li>
          <a href="#faq"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>FAQ</a>
        </li>
        <li>
          <a href="/blogs"><MdKeyboardArrowRight style={{color:'#FC4243'}}/>Our Blog</a>
        </li>
      </ul>
    </li>
    <li class="nav__item">
      <h1 style={{fontFamily:'Lato, sans-serif'}} class="nav__title">Contact Us</h1>

      <ul class="nav__ul">
        <li>
          <a href="tel:+1-833-372-9071"><FaPhoneAlt style={{color:'#FC4243'}} className='me-2'/>1-833-372-9071</a>
        </li>

        <li>
          <a href="mailto:sales@allcanadavanlines.com"><IoMdMail style={{color:'#FC4243'}} className='me-2'/>sales@allcanadavanlines.com</a>
        </li>
        <li>
          <a target="_blank" href="https://www.google.com/maps/place/515+Kingston+Rd+W,+Ajax,+ON+L1S+6M1,+Canada/@43.8551518,-79.0511801,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4df97c5bd92fd:0xb3fe654394c8e4a!8m2!3d43.8551518!4d-79.0511801!16s%2Fg%2F11c2drlvm_?entry=ttu"><MdLocationPin style={{color:'#FC4243'}} className='me-2'/>
          515 Kingston Rd W, Ajax,<br/>
                      <p style={{paddingLeft:'25px'}}>ON L1S 6M1, Canada</p></a>
        </li>
      </ul>
    </li>
    
  </ul>

</footer>
<div style={{backgroundColor:'#FC4243'}}>
  <div className='container'>
  <Row className='FooterLast'>
    <Col xs={12} md={9}>
       <p style={{fontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 0px',cursor:'pointer'}}>Copyright © 2024 All Canada Van Lines Inc. All rights reserved.</p>
    </Col>
    <Col xs={12} md={3} className='py-2' >
      {/* <Link to='#' style={{fontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 20px 0px 0px',cursor:'pointer'}}>Terms of Service</Link> */}
      <a href='/privacyPolicy' style={{ontWeight: '500',fontFamily: 'sans-serif',color:'#ffff',margin:'10px 0px',cursor:'pointer'}}>Terms and conditions</a>
    </Col>
  </Row>
  </div>
</div>
    </div>
{/* -------------------------- END SERVICE --------------------------- */}
    <div>
    </div>

  </div>
  )
}

export default Home








