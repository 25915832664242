import React,{useEffect} from 'react'
import Header from '../components/Header';
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import { IoIosArrowDropleft } from "react-icons/io";

const ThankPage2 = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  return (
    <div>
         <Header />
     <div>
      <div class="success-animation">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
         <div className='mt-4' style={{textAlignLast: 'center'}}>
         <h4 style={{fontFamily:'sans-serif'}}>Thanks for reaching ACVL</h4>
        <h6 style={{fontFamily:'emoji'}}>We will get back to you as soon as possible </h6>
        <Link to='/' style={{textDecoration:'none'}}><Button style={{borderColor:'#F50202', backgroundColor:'#F50202', color:'#ffff', marginTop:'30px'}}><IoIosArrowDropleft className='me-1 mb-1' size={15}/>Back Home</Button></Link>
         </div>
      </div>      
     </div>
    </div>
  )
}

export default ThankPage2
