import React,{useState,useEffect} from 'react'
import Header from '../components/Header'
import ContactBannerImg from '../images/Contactus.webp'
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Button} from 'react-bootstrap'
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { ImLocation2 } from "react-icons/im";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import { IoIosSend } from "react-icons/io";
import './pages.css'
import Footer from  '../components/FooterMain'
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate ,useLocation} from 'react-router-dom';
import { IoLocationSharp } from "react-icons/io5";

const Contact = () => {

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);



  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    help: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Check if any field is empty
    if (!formData.name || !formData.email || !formData.subject || !formData.help) {
      // Show alert message if any field is empty
      // alert('Please fill out all fields');
      toast.warn('Please fill out all fields', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
      return; // Stop form submission
    }
  
    setLoading(true); // Set loading to true when the form is submitted
  
    // Make POST request to the API
    fetch('https://allcanadavanlines.com/acvlbackend/api/contact_form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // Simulating an asynchronous request with setTimeout
      setTimeout(() => {
        toast.success('Successfully form submitted', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        });
        setFormData({
          name: '',
          email: '',
          subject: '',
          help: ''
        });
        setLoading(false);
        navigate('/thankyou2')
      }, 2000);
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error here, such as showing an error message to the user
      setLoading(false); // Set loading to false when an error occurs
    });
  };
  


  return (
    <div>
      <Header/>
      <ToastContainer />
      {/* <div style={{position: 'relative', height:'340px'}}>
        <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(69, 67, 69, 0.5)',}} />
        <div style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
        <Fade left>
        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
            <h1 style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'math' }}>CONTACT US</h1>
            <div style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
            <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer'}}>Home</h6></Link>
            <h6 style={{color: '#ffffff'}}>/ Contact us</h6>
            </div>
        </div></Fade>
        </div>
       </div>
    </div> */}
     <div>
          <div style={{position: 'relative', height:'340px'}}>
          <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080',}} />
          <div className='bgServiceImage' style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
          <Zoom right>
          <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <h1 className='serviceTitle' style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'Lato',fontSize: 'xxx-large',fontWeight: '600' }}>CONTACT US</h1>
              <div className='serviceSubTitle' style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
                <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer'}}>Home</h6></Link>
                <h6 style={{color: '#ffffff'}}>/ Contact us</h6>
              </div>
          </div></Zoom>
          </div>
        </div>
          </div>
        </div>
{/* ----------- Email Number Address -------- */}
    <div className='my-5 container'>
    <Fade right>
       <Row>
        <Col xs={12} md={4} className='mb-2'>
        <div className='d-flex'>
        <a href="mailto:sales@allcanadavanlines.com" style={{textDecoration:'none',color:'#000'}}><div style={{ borderRadius:'50%', border:'2px solid #FC4243', height:'90px', width:'90px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
            <IoMdMail size={50} />
        </div></a>
        <div style={{marginTop: 'auto'}}>
          <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700'}}>Email Here</h4>
          <p className='ms-3' style={{fontFamily: 'sans-serif'}}>sales@allcanadavanlines.com</p>
        </div>
        </div>
        </Col>
        <Col xs={12} md={4} className='mb-2'>
            <div className='d-flex'>
            <a href="tel:+1-833-372-9071"  style={{textDecoration:'none',color:'#000'}}>  <div style={{ borderRadius:'50%', border:'2px solid #FC4243', height:'90px', width:'90px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
                <IoCall size={50} />
            </div></a>
            <div style={{marginTop: 'auto'}}>
            <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700'}}>Call Here</h4>
            {/* <p className='ms-3' style={{fontFamily: 'sans-serif'}}>1-833-372-9071</p> */}
            <a href="tel:+1 4373740550"  style={{textDecoration:'none',color:'#000'}}>  <p className='ms-3 callContact ' style={{fontFamily: 'sans-serif',marginBottom: '5px'}} >Toronto / Ontario(ON) <br/> +1 4373740550</p></a>
            <a href="tel:+1 7806700659"  style={{textDecoration:'none',color:'#000'}}>  <p className='ms-3 callContact' style={{fontFamily: 'sans-serif'}}>Edmonton / Alberta(AB) <br/> +1 7806700659</p></a>
            </div>
            </div>
        </Col>
        <Col xs={12} md={4} className='mb-2'>
            <div className='d-flex'>
            <div style={{ borderRadius:'50%', border:'2px solid #FC4243', height:'90px', width:'90px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='contactUsCircle'>
            <a target='_blank' href="https://www.google.com/maps/place/515+Kingston+Rd+W,+Ajax,+ON+L1S+6M1,+Canada/@43.8551518,-79.0511801,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4df97c5bd92fd:0xb3fe654394c8e4a!8m2!3d43.8551518!4d-79.0511801!16s%2Fg%2F11c2drlvm_?entry=ttu"  style={{textDecoration:'none',color:'#000'}}>  
              <ImLocation2 size={50} />
            </a>
            </div>
            <div style={{marginTop: '15px'}}>
            <h4 className='ms-3' style={{fontFamily: 'sans-serif',fontWeight: '700'}}>Visit Here</h4>
            <a target='_blank' href="https://www.google.com/maps/place/515+Kingston+Rd+W,+Ajax,+ON+L1S+6M1,+Canada/@43.8551518,-79.0511801,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4df97c5bd92fd:0xb3fe654394c8e4a!8m2!3d43.8551518!4d-79.0511801!16s%2Fg%2F11c2drlvm_?entry=ttu"  style={{textDecoration:'none',color:'#000'}}>  
             <p className='ms-3 callContact' style={{fontFamily: 'sans-serif'}}><IoLocationSharp style={{color:'red'}}  /> 515 Kingston Rd W,<br/>Ajax, ON L1S 6M1,
              <br/>Canada
                </p></a>
                <a target='_blank' href="https://www.google.com/maps/place/515+Kingston+Rd+W,+Ajax,+ON+L1S+6M1,+Canada/@43.8551518,-79.0511801,17z/data=!3m1!4b1!4m6!3m5!1s0x89d4df97c5bd92fd:0xb3fe654394c8e4a!8m2!3d43.8551518!4d-79.0511801!16s%2Fg%2F11c2drlvm_?entry=ttu"  style={{textDecoration:'none',color:'#000'}}> 
            <p className='ms-3 callContact' style={{fontFamily: 'sans-serif', cursor:'pointer'}}><IoLocationSharp style={{color:'red'}} /> 3111 Tokala Trl,<br/>London, ON N6H 5K3,
            <br/>Canada
              </p></a>
              <a target='_blank' href="https://www.google.com/maps/dir/13.0875392,80.1767424/All+Canada+Van+Lines/@2.3726572,76.9112857,3z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x539ff5aa8b466fbd:0xf442ad4c7794f32e!2m2!1d-113.5987955!2d53.4280497?hl=en&entry=ttu"  style={{textDecoration:'none',color:'#000'}}> 
            <p className='ms-3 callContact' style={{fontFamily: 'sans-serif'}}><IoLocationSharp style={{color:'red'}} /> 3673 Allan Dr SW,<br/>Edmonton, AB T6W 1A4,
            <br/>Canada
              </p></a>
            </div>
            </div>
        </Col>
       </Row>
       </Fade>
    </div>

    <div className='my-5 Contactcard'>
    <Paper elevation={12} style={{width:'90%', margin:'0px auto'}}>
          <div className='container'>
        <Row className='py-4' >
          <Col className='ContactImageTouch'> 
          <Fade left>
            <img src='https://i.pinimg.com/originals/93/c7/44/93c744bcde1780c94bb1d3f03991f8a7.gif' style={{width:'100%', marginTop:'50px'}}  alt='ContactImage'  />
            </Fade>
          </Col>
          <Col>
          <Fade right>
             <h2 className='mb-4' style={{fontFamily: 'sans-serif'}}>Get in Touch</h2>
             <div>
             <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              label="User Name"
              variant="outlined"
              style={{ width: '100%' }}/>
            <TextField
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="Email"
              variant="outlined"
              style={{ width: '100%', marginTop: '20px' }}/>
            <TextField
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              label="Subject"
              variant="outlined"
              style={{ width: '100%', marginTop: '20px' }}/>
            <Form.Group className="mt-3">
              <Form.Control
                name="help"
                value={formData.help}
                onChange={handleChange}
                as="textarea"
                rows={6}
                placeholder='How can help you?'
                style={{ borderColor: '#C4C4C4' }} />
            </Form.Group>
            <Button
              type="submit"
              style={{ marginTop: '15px', width: '100%', backgroundColor: '#FC4243', borderColor: '#000' }}
              disabled={loading}>
              {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                <>SEND MESSAGE <IoIosSend style={{ marginBottom: '5px' }} size={20} /></>
              )}
            </Button>
          </form>   
           </div>
           </Fade>
          </Col>
        </Row>  
         </div> 
        </Paper>
    </div>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>
{/* ------- Footer ---------- */}
    <div>
      <Footer/>
    </div>
    </div>
  )
}

export default Contact
