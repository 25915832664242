import React,{useEffect} from 'react'
import Header from '../components/Header'
import {Link} from 'react-router-dom';
import ContactBannerImg from '../images/MovingSupplier.jpg'
import serivePlan from '../images/MovingBoxes.jpg'
import serivePlan2 from '../images/PackingTape.jpg'
import serivePlan3 from '../images/BubbleWrap.jpg'
import serivePlan4 from '../images/PackingPaper.jpg'
import serivePlan8 from '../images/FurnitureCovers.jpg'
import serivePlan6 from '../images/MattressCovers.jpg'
import welcometruck from '../images/welcome-truck.png'
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Card,Button} from 'react-bootstrap';
import { AiFillSafetyCertificate } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { FaTruckFast } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Trip from '../images/Trip.jpg'
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Footer from '../components/FooterMain'
import MoreService from '../components/MoreService'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RecyclingIcon from '@mui/icons-material/Recycling';
import {Helmet} from 'react-helmet'

const MovingSupplier = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  
    useEffect(() => {
    // Function to get the IP address
    const fetchIpAddress = async () => {
      try {
        // Fetch the IP address from IPify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;

        // Send the IP address to your API
        await sendIpAddressToApi(ipAddress);

      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Function to send the IP address to your API
    const sendIpAddressToApi = async (ipAddress) => {
      try {
        const response = await fetch('https://allcanadavanlines.com/acvlbackend/api/ipaddress', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipaddress: ipAddress }),
        });

        if (response.ok) {
          console.log('IP address sent successfully');
        } else {
          console.error('Failed to send IP address');
        }
      } catch (error) {
        console.error('Error sending IP address:', error);
      }
    };

    // Call the function to fetch IP and send it to your API
    fetchIpAddress();

  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div>
            <Helmet>
        <title>ACVL| Moving services provider| transport your belongings safely| Moving company in Alberta, Canada </title>
        <meta name="description" content="We provide everything you need to pack and transport your belongings safely. Trust ACVL for high-quality supplies and seamless moving experiences." />
      </Helmet>
        <Header/>
        {/* Bannar */}
        <div>
          <div style={{position: 'relative', height:'340px'}}>
          <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080',}} />
          <div className='bgServiceImage' style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
          <Zoom right>
          <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <h1 className='serviceTitle' style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'Lato, sans-serif',fontSize: 'xxx-large',fontWeight: '600' }}>MOVING SUPPLIES</h1>
              <div className='serviceSubTitle' style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
              <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer',fontFamily:'Lato, sans-serif'}}>Home</h6></Link>
              <h6 style={{color: '#ffffff',fontFamily:'Lato, sans-serif'}}>/ Moving Supplies</h6>
              </div>
          </div></Zoom>
          </div>
        </div>
          </div>
        </div>
         {/* Circle-Heading */}
        <div className='ms-4 mt-5 pb-4'>
          <Fade right>
          <hr className='hrService'/>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>Moving supplies in Canada</h2>
          <h5 style={{lineHeight: '1.6',marginRight: '7px',fontFamily:'Lato, sans-serif',fontWeight: '400'}}>At All Canada Van Lines (ACVL), we understand that a successful move relies on having the right tools and materials. Our “Moving Supplies” service offers a comprehensive selection of high-quality moving essentials to ensure a smooth and stress-free relocation.</h5>
          </Fade>
        </div>
        {/* Circle */}
        <div className='container my-5'>
        <Fade left>
          <Row className=''>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center',}}>
              <AiFillSafetyCertificate size={90}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Quality Assured</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <ShoppingBagIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>One-Stop Shop</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <EventAvailableIcon style={{fontSize:'85px', marginBottom:'0px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Expert Advice Available</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid red',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
             <RecyclingIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Eco-Friendly</h5>
            </Col>
          </Row></Fade>
        </div>
        {/* Detailed List of Services Included */}
        <div className='pt-4'>
        <Fade left>
          <div className='container'>
          <hr className='hrService '/>
          <h2 style={{fontFamily: 'Lato, sans-serif', marginRight: '1px'}}>Detailed List of Services Included</h2>
          </div></Fade>
          <div className='px-3 pt-4'>
          <Fade right>
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan} style={{height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Moving Boxes - For All Sizes Moves</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Full selection of high-quality standard and specialty moving boxes to efficiently pack, transport, and organize items.                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan2}  style={{ height:'191px'}}/>
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Packing Tape with Dispensers</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Premium packing tape and dispensers for securing boxes to withstand transport friction and climate exposure.                      </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan3}  style={{ height:'191px',}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Bubble Wrap Rollers</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Bubble cushioning by foot or roll with built-in dispensers for shielding fragile items during moves.                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> 
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan4} style={{ height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Premium Packing Paper For Breakables</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Superior-quality packing paper cushioning is available for protecting delicate items during local or long-distance moves                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan6}  style={{ height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Mattress Covers</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Durable, breathable mattress bags to protect bedding from dust, dirt, and moisture exposure during relocation. Prevents scratches.                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan8}  style={{ height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Furniture Pads & Moving Blankets</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Thick quilted pads and heavy-duty blankets to shield furniture from nicks, dings, and scratches in moving trucks. </Card.Text>                  </Card.Body>
                </Card>
              </Col>
            </Row></Fade>
          </div>
        </div>
        {/* Frequently Asked Questions */}
        <div className='container-fluid'>
          <div className='ms-4 my-4'>
           <hr className='hrService'/>
           <h2 style={{fontFamily: 'Lato, sans-serif'}}>Frequently Asked Questions</h2>
          </div>
        <Row className='pt-5 FQASerivice'>
          <Col xs={12} md={6} className='serviceFaq'>
          <Fade left>
           <img style={{width:'600px'}} src={welcometruck} alt='welcometruck'/></Fade>
          </Col>
          <Col xs={12} md={6} className=''>
          <Fade right>
            <Accordion sty defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What types of boxes and packaging supplies do you provide?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                      We provide a wide range of high-quality boxes and packaging materials to ensure your belongings are properly protected during a move. Our selection includes sturdy cardboard boxes in various sizes, from small book boxes to large wardrobe boxes.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6 style={{fontFamily: 'lato, sans-serif'}}>Do you sell specialty boxes like wardrobe boxes, mattress boxes, etc?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Yes, we offer specialty boxes designed for specific items, including wardrobe boxes for hanging clothes, mirror boxes for mirrors and framed artwork, mattress boxes for easy transport of mattresses and box springs, and more.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What types of tape, bubble wrap, and other packing items do you offer?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    In addition to boxes, we carry professional-grade packing tape in various widths, bubble wrap rolls and sheets, packing paper, foam pouches, stretch wrap, and other essential packing materials to secure your belongings during transit.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>Can I purchase bundles or packages of assorted moving supplies?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                      Absolutely! We offer convenient moving supply bundles and kits that contain an assortment of essential boxes, tape, bubble wrap, and other materials, making it easy to purchase everything you need for your move in one package.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you provide free or discounted boxes with moving services?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                      When you book moving services with us, we do provide complimentary boxes and discounted supplies to help offset the costs associated with your relocation. Additionally, we offer loyalty discounts on supplies for our repeat customers.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I purchase moving boxes and supplies from you?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    You can purchase moving boxes and supplies from us in several convenient ways. Place your order online through our e-commerce platform, visit one of our retail stores, or speak with one of our moving consultants to add supplies to your moving package.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>When can I pick up the moving supplies I purchased?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                      If you place an order online or through our retail stores, you can pick up your moving supplies during our regular business hours, which are Monday through Saturday from 8 AM to 6 PM.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion></Fade>
            </Col>
        </Row>
       </div>
       {/* TESTIMONIALS */}
      <div className='container'>
       <div className='pt-5 testimonialsNumberSer2'>
        <hr className='hrService'/>
         <p style={{fontFamily: 'Lato, sans-serif', color:'#FC4243'}}>Clients Tales</p>
        </div>
        <div>
         <Row>
          <Col xs={12} md={6} className='testimonialsNumberSer'>
          <Zoom right>
            <h2 style={{fontFamily: 'fantasy',textAlign: '-webkit-match-parent'}}>2000 +</h2>
            <h5 style={{fontFamily:'sans-serif'}}>Satisfied Customers</h5>
            <span>
            We've helped thousands relocate across Canada with ease through our commitment to exceptional service.              </span></Zoom>
          </Col>
          <Col xs={12} md={6}>
          <div className='pb-5 testimonialsCard'>
            <Carousel>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem', backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Balaji</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    The moving crew arrived at my house on time and loaded my belongings with care and attention to detail and finished in the estimated time as per the quote.
                    Because of a delay in moving into my apartment, my stuff had to be stored in the truck for 3 days.
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem',backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Elana in Ottawa</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>Toronto, ON</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    Abbas and his team are the best movers ever. I have used them about 5 different times over the past few years for downtown and East Toronto short haul moves
                    They are careful, considerate of property, always on time, good listeners and good communicators. Highly recommend!
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem',backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Lawn Maintenance</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>On Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                     Called them in the last-minute. They showed as they promised on time. Did an excellent and professional packing and delivered them to my new home with a competitive charge
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
            </Carousel>
            </div>
          </Col>
         </Row>
        </div>
      </div>
      <div  className='pb-5'>
        <MoreService/>
      </div>
      {/* Footer */}
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>
      <div className='mt-1'>
        <Footer/>
      </div>
      
    </div>
  )
}

export default MovingSupplier


