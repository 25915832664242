import React, { useEffect } from 'react';
import acvlbanner from '../images/TestMoli.png';
import TestHumen from '../images/TestHumen.png';
import { FaCheck } from "react-icons/fa";
import { Row, Col, Card } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Trip from '../images/Trip.jpg'
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const Testimonial = () => {


  return (
    <div>
      <div style={{ backgroundImage: `url(${acvlbanner})`, backgroundRepeat: 'no-repeat' }}>
        <div className='container' >
          <Row>
            <Col className='TestmoniCol'>
              <img src={TestHumen} alt='km' style={{ width: '533px' }} />
            </Col>
            <Col >
              <div style={{ marginTop: '50px' }}>
                <h6 style={{ color: '#FC4243',fontFamily:'Lato, sans-serif',fontSize: 'larger' }}>Clients Tales</h6>
                <h3 style={{ fontWeight: '700', fontFamily:'Lato, sans-serif',fontSize: '31px' }}>Read Stories from Our Satisfied Clients</h3>
              </div>
              <Row className='mt-4'>
                <Col xs={12} md={6}>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Your Requirement</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Moving Is Quick & Easy</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />We Are Affordable</h6>
                </Col>
                <Col xs={12} md={6}>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2'  />Save & Secure Move</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Delivery On Time</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Transporting Goods</h6>
                </Col>
              </Row>
              
              <div className='mt-5 '>
              <Carousel className='carouselClass'>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Balaji</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    The moving crew arrived at my house on time and loaded my belongings with care and attention to detail and finished in the estimated time as per the quote.
                    Because of a delay in moving into my apartment, my stuff had to be stored in the truck for 3 days.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Elana in Ottawa</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>Toronto, ON</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    Abbas and his team are the best movers ever. I have used them about 5 different times over the past few years for downtown and East Toronto short haul moves
                    They are careful, considerate of property, always on time, good listeners and good communicators. Highly recommend!
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Lawn Maintenance</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>On Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#FC4243'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                     Called them in the last-minute. They showed as they promised on time. Did an excellent and professional packing and delivered them to my new home with a competitive charge
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
              </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;


