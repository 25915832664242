import React from 'react'
import Truck from '../images/Truck.gif'
import Bin from '../images/Bin.gif'
import Trolley from '../images/Trolley.gif'
import Box from '../images/Box.gif'
import room from '../images/room.gif'
import Location from '../images/Location.gif'
import {Row,Col,Card} from 'react-bootstrap'
import Fade from 'react-reveal/Fade';

const ServicePage = () => {
  
  return (
    <div>
      <div className='ServiceBanner container-fluid'>
        <div className='text-center pt-4'>
         <Fade right><h6 style={{color:'#ffff',fontFamily: 'Lato, sans-serif'}}>OUR SERVICE</h6></Fade> 
          <Fade left><h1 style={{color:'#ffff',fontFamily: 'Lato, sans-serif',fontWeight: '700'}}>Services We Provide</h1></Fade>
        </div>
      <Row className='px-5 pt-5 justify-content-center'>
      <Col md={4}>
      <a href='/local-movers' style={{textDecoration:'none'}}>
         <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={Trolley} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'lato, sans-serif',fontWeight: '600'}}>Local Movers</h4>
            <Card.Text className='text-center' style={{fontFamily: 'lato, sans-serif'}}>
            We'll handle your local move swiftly, attentively, and professionally, allowing you to focus on fresh starts awaiting
            </Card.Text>
            <a href='/local-movers' className='atagServ'><p className='text-center mb-0 hoverRead' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a> 
      </Col>
      <Col md={4}>
      <a href='/long-distance-movers' style={{textDecoration:'none'}}>
        <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={Truck} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'Lato, sans-serif',fontWeight: '600'}}>Long Distance Movers</h4>
            <Card.Text className='text-center' style={{fontFamily: 'Lato, sans-serif'}}>
            Your cross-country journey begins! Our packing wizards and transportation adventurers protect precious cargo with ease
            </Card.Text>
            <a href='/long-distance-movers' className='atagServ'><p className='text-center mb-0' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a>
      </Col>
      <Col md={4}>
      <a href='/junk-removal' style={{textDecoration:'none'}}>
      <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={Bin} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'Lato, sans-serif',fontWeight: '600'}}>Junk Removal</h4>
            <Card.Text className='text-center' style={{fontFamily: 'Lato, sans-serif'}}>
               Dispose of unwanted goods sustainably through our eco-friendly junk removal solution. Our team handles waste decluttering
             </Card.Text>
            <a href='/junk-removal' className='atagServ'><p className='text-center mb-0' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a>
      </Col>
      </Row>
      <Row className='px-5 py-5 justify-content-center'>
      <Col md={4}>
      <a href='/packaging-services' style={{textDecoration:'none'}}>
      <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={Box} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'lato, sans-serif',fontWeight: '600'}}>Packing Services</h4>
            <Card.Text className='text-center' style={{fontFamily: 'Lato, sans-serif'}}>
               Ensure priceless possessions stay damage-free with our specialty packing service using industry-leading techniques materials and care
            </Card.Text>
            <a href='/packaging-services' className='atagServ'><p className='text-center mb-0' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a> 
      </Col>
      <Col md={4}>
      <a href='/moving-supplier' style={{textDecoration:'none'}}>
      <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={Location} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'lato, sans-serif',fontWeight: '600'}}>Moving Supplies</h4>
            <Card.Text className='text-center' style={{fontFamily: 'Lato, sans-serif'}}>
             It’s moving time! We equip courageous movers with a trusty arsenal of superior bubble wrap and other supplies for organization domination 
            </Card.Text>
            <a href='/moving-supplier' className='atagServ'><p className='text-center mb-0' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a>
      </Col>
      <Col md={4}>
      <a href='/storage-services' style={{textDecoration:'none'}}>
      <Card className='zoom' style={{ width: '18rem', margin:'auto', cursor:'pointer', marginBottom:'10px',transition: 'transform 0.3s' }}>
          <Card.Body>
            <Card.Title style={{textAlign:'center'}}>
                <img src={room} alt='locacal Mover' style={{ width: '50%'}}/>
            </Card.Title>
            <h4 className="mb-2 text-center" style={{fontFamily: 'lato, sans-serif',fontWeight: '600'}}>Storage Services</h4>
            <Card.Text className='text-center' style={{fontFamily: 'Lato, sans-serif'}}>
            Settling made simple! Our storage sanctuaries promise sweet hibernation with 24/7 protection until the fated day of their glorious homecoming arrives. 
            </Card.Text>
            <a href='/storage-services' className='atagServ'><p className='text-center mb-0' style={{fontFamily: 'Lato, sans-serif'}}>Read more</p></a>
          </Card.Body>
        </Card></a>
      </Col>
      </Row>
      </div>
    </div>
  )
}

export default ServicePage
