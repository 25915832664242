import React from 'react'
import HomestarLogo from '../images/HomestarLogo.png'
import zohologo from '../images/zohologo.webp'
const ClientScroll = () => {
  return (
    <div>
      <div class="sliderL">
	<div class="slide-track">
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
		<div class="slide2">
			<img src={HomestarLogo} height="100" width="250" alt="" />
		</div>
		<div class="slide2">
			<img src={zohologo} height="100" width="200" alt="" />
		</div>
	</div>
</div>
    </div>
  )
}

export default ClientScroll




// import React from 'react'
// const ClientScroll = () => {
//   return (
//     <div>
//       <div className="marquee-container">
//       <div className="marquee">
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//         <img style={{ width: '10%', marginRight: '20px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3oTnFxD2_a6mwqz7EmrCZjwgQV1lFWI-Aow&usqp=CAU" alt="logoImage" />
//       </div>
//     </div>
//     </div>
//   )
// }

// export default ClientScroll
