import React from 'react'

const Test = () => {
  return (
    <div>
      <l1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus deserunt, necessitatibus pariatur vitae fuga quisquam quae harum ab tempore aspernatur qui alias magnam iste animi tenetur modi aliquam, dolorum repellendus.</l1>
    </div>
  )
}

export default Test
