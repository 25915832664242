import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Paper from '@mui/material/Paper';
import { Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate ,useLocation} from 'react-router-dom';
import { MdKeyboardArrowRight} from 'react-icons/md';
import FormImage from '../images/delivery1.jpg';
import {Fade,Zoom} from 'react-reveal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { ToastContainer, toast } from 'react-toastify';
import { FaArrowLeftLong } from "react-icons/fa6";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar, utils } from "react-modern-calendar-datepicker";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Calendar} from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/red.css"

const CalenderForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [existingData, setExistingData] = useState({});
  const today = new Date();

  
  useEffect(() => {
    const data = location.state && location.state.data;
    setExistingData(data);
    console.log('Test ->',data);
    if (!data || !data.from || !data.to) {
      navigate('/');
    } else {
      if (data.selectedDate) {
        setSelectedDate(dayjs(data.selectedFormattedDate));
}
      }
  }, [location.state]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleNextButtonClick(date)
    //let data = {"selectedDate":"08-02-2024"}
    //navigate('/PropertySelect', { state: { data: data } });
  };

  const handleNextButtonClick = (selectedDate2) => {
    //alert('hai');
    // event.preventDefault();   
     if (selectedDate2) {
      const formattedDate = selectedDate2.format('YYYY-MM-DD'); 
      const data = {
        from: existingData.from,
        to: existingData.to,
        ...(existingData || {}),  
        selectedDate: selectedDate2,
        selectedFormattedDate: formattedDate,
      };
      console.log("selectedDate2", selectedDate2);
      console.log("send to navigate", data);

      console.log('NEXT button clicked. Navigating with data:', data);
      navigate('/PropertySelect', { state: { data: data } });
      // <Link to={{ pathname: '/PropertySelect', state: { data: data } }}>Go to Property Select</Link>
    } else {
      toast.warn('Please select a date', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FC4243', // Change to your desired color
      },
    },
  });

  return (
    <div>
      <Header />
      <ToastContainer />

      <div className="container my-3">
        <div className='formBoxsh'>
          <div>
            <Row>
              <Col xs={5} style={{ padding: '0px' }} className="FormImages">
                <img src={FormImage} alt="images" style={{ width: '100%', height: '460px', borderRadius: '10px 0px 0px 10px' }}/>
              </Col>
              <Col xs={12} md={7}  style={{ padding: '0px', position: 'relative' }}>
                <div
                  style={{
                    width: '55%',
                    height: '10%',
                    borderRadius: '0px 10px 10px 0px',
                    border: '1px solid #FC4243',
                    marginTop: '10px',
                    fontWeight: '600',
                    backgroundColor: '#FC4243',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>I'd like to move on</h5></Zoom>
                </div>
                <Link to='/'> <button class="button-70" style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button></Link>
                <Fade right>
                  <div className='calenderHeight' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                        {/* <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            disablePast
                            style={{
                              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                            }}
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderDay={(day, _value, DayComponentProps) => {
                                  if (_value && day.isSame(_value, 'day')) {
                                    return (
                                      <DayComponentProps
                                        style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
                                        {day.format('D')}
                                      </DayComponentProps>
                                    );
                                  }
                                  return <DayComponentProps>{day.format('D')}</DayComponentProps>;
                                }}
                              />
                            </LocalizationProvider>
                          </ThemeProvider> */}
                          <Calendar
                          numberOfMonths={2}
                          value={selectedDate}
                          onChange={handleDateChange}
                          className="red"
                          highlightToday={false}
                          onOpenPickNewDate={false}
                          minDate={today} /> 
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>  
        </div>
    </div>
  );
};

export default CalenderForm;






// import React, { useState, useEffect } from 'react';
// import Header from '../components/Header';
// import Paper from '@mui/material/Paper';
// import { Row, Col, Button } from 'react-bootstrap';
// import { Link, useNavigate ,useLocation} from 'react-router-dom';
// import { MdKeyboardArrowRight} from 'react-icons/md';
// import FormImage from '../images/delivery1.jpg';
// import {Fade,Zoom} from 'react-reveal';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import dayjs from 'dayjs';
// import 'dayjs/locale/en';
// import { ToastContainer, toast } from 'react-toastify';
// import { FaArrowLeftLong } from "react-icons/fa6";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import { Calendar, utils } from "react-modern-calendar-datepicker";
// import ServicePage from '../components/ServicePage'
// const CalenderForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [existingData, setExistingData] = useState({});


//   useEffect(() => {
//     const data = location.state && location.state.data;
//     setExistingData(data);
//     console.log('Test ->',data);
//     if (!data || !data.from || !data.to) {
//       navigate('/');
//     } else {
//       if (data.selectedDate) {
//         setSelectedDate(dayjs(data.selectedFormattedDate));
// }
//       }
//   }, [location.state]);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     handleNextButtonClick(date)
//     //let data = {"selectedDate":"08-02-2024"}
//     //navigate('/PropertySelect', { state: { data: data } });
//   };

//   const handleNextButtonClick = (selectedDate2) => {
//     //alert('hai');
//     // event.preventDefault();   
//      if (selectedDate2) {
//       const formattedDate = selectedDate2.format('YYYY-MM-DD'); 
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         ...(existingData || {}),  
//         selectedDate: selectedDate2,
//         selectedFormattedDate: formattedDate,
//       };
//       console.log("selectedDate2", selectedDate2);
//       console.log("send to navigate", data);

//       console.log('NEXT button clicked. Navigating with data:', data);
//       navigate('/PropertySelect', { state: { data: data } });
//       // <Link to={{ pathname: '/PropertySelect', state: { data: data } }}>Go to Property Select</Link>
//     } else {
//       toast.warn('Please select a date', {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//         })
//     }
//   };

//   return (
//     <div>
//       <Header />
//       <ToastContainer />

//       <div className="container my-3">
//         <Paper elevation={24} style={{ borderRadius: '10px' }}>
//           <div>
//             <Row>
//               <Col xs={5} style={{ padding: '0px' }} className="FormImages">
//                 <img src={FormImage} alt="Image" style={{ width: '100%', height: '460px', borderRadius: '10px 0px 0px 10px' }}/>
//               </Col>
//               <Col xs={7}  style={{ padding: '0px', position: 'relative' }}>
//                 <div
//                   style={{
//                     width: '55%',
//                     height: '10%',
//                     borderRadius: '0px 10px 10px 0px',
//                     border: '1px solid #FC4243',
//                     marginTop: '10px',
//                     fontWeight: '600',
//                     backgroundColor: '#FC4243',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}>
//                   <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>I'd like to move on</h5></Zoom>
//                 </div>
//                 <Link to='/'> <button class="button-70" style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button></Link>
//                 <Fade right>
//                   <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
//                    <LocalizationProvider  dateAdapter={AdapterDayjs}>
//                        <DateCalendar
//                         disablePast
//                         style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
//                         colorPrimary="#FC4243"
//                         value={selectedDate}
//                         onChange={handleDateChange}/>  
//                         </LocalizationProvider>
//                   </div>
//                 </Fade>
//               </Col>
//             </Row>
//           </div>
//         </Paper>

//       </div>

//      {/* <div className='my-5'>
//        <ServicePage/>
//       </div>  */}
//     </div>
//   );
// };

// export default CalenderForm;
